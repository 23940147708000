<template>
  <aside class="sidebar">
    <div class="holder">
      <h3>
        {{ $t("header.AboutUs") }}
      </h3>
      <ul>
        <li :class="{ current: $route.name == 'WhoAreWe' }">
          <router-link to="/about-us/who-are-we">
            {{ menu1 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'WhyRubii' }">
          <router-link to="/about-us/why-rubii">
            {{ menu2 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'ShowShouldUseRubii' }">
          <router-link to="/about-us/who-should-use-rubii">
            {{ menu3 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'RentToOwn' }">
          <router-link to="/about-us/rent-to-own">
            {{ menu4 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'NonAggressiveSelling' }">
          <router-link to="/about-us/non-aggressive-selling">
            {{ menu5 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'Commissions' }">
          <router-link to="/about-us/commissions">
            {{ menu6 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'VisiMisi' }">
          <router-link to="/about-us/visi-misi">
            {{ menu7 }}
          </router-link>
        </li>
        <li :class="{ current: $route.name == 'Article' }">
          <router-link to="/about-us/article">
            {{ menu8 }}
          </router-link>
        </li>
      </ul>
    </div>
    <!-- end of holder -->
  </aside>
  <!-- end of sidebar -->
</template>

<script>
import $ from "jquery";

export default {
  name: "AboutUsSidebar",
  data() {
    return {
      menu1: null,
      menu2: null,
      menu3: null,
      menu4: null,
      menu5: null,
      menu6: null,
      menu7: null,
      menu8: null
    };
  },
  mounted() {
    this.getContent();

    if (window.matchMedia("only screen and (max-width: 991px)").matches) {
      $(".inner-sidebar .sidebar h3").on("click", function() {
        $(this).toggleClass("expanded");
      });
    }
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.menu1 = response.data.data.rows.find(
            item => item.menu == 1
          ).menuName;
          this.menu2 = response.data.data.rows.find(
            item => item.menu == 2
          ).menuName;
          this.menu3 = response.data.data.rows.find(
            item => item.menu == 3
          ).menuName;
          this.menu4 = response.data.data.rows.find(
            item => item.menu == 4
          ).menuName;
          this.menu5 = response.data.data.rows.find(
            item => item.menu == 5
          ).menuName;
          this.menu6 = response.data.data.rows.find(
            item => item.menu == 6
          ).menuName;
          this.menu7 = response.data.data.rows.find(
            item => item.menu == 7
          ).menuName;
          this.menu8 = response.data.data.rows.find(
            item => item.menu == 8
          ).menuName;
        });
    }
  }
};
</script>
